'use client';

import Flag from '@components/Flag';
import type { Market } from '@context/common';
import { useMarket } from '@context/marketContext';
import { Listbox } from '@headlessui/react';
import { ArrowDownIcon } from '@ngg/icons';
import { useCallback, useMemo } from 'react';

import { envConfig } from '@/config/env';
import { cn } from '@/lib/utils';
import { getAppLocale } from '@/utils/const';

import MarketSelectorOption from './MarketSelectorOption';

export default function MarketSelectorDropdown({
    filter = () => true,
    classNames,
    disabled = false,
}: {
    filter?: (market: Market) => boolean;
    disabled?: boolean;
    classNames?: {
        wrapper?: string;
        input?: string;
        list?: string;
    };
}) {
    const { state, onSave } = useMarket();

    const value = useMemo(
        () => (state.locale === 'eu' ? state.markets.eu : state.market),
        [state.market, state.markets.eu, state.locale],
    );

    const markets: Market[] = useMemo(
        () =>
            Object.values(state.markets).sort((a: Market, b: Market) =>
                a.label.localeCompare(b.label),
            ),
        [state.markets],
    );

    const onChange = useCallback(
        (payload: Market) => {
            if (!payload) {
                return;
            }

            if (payload.code === 'se') {
                onSave({ link: 'https://www.nordiskagalleriet.se' });
                return;
            }

            if (payload.code === 'no') {
                onSave({ link: 'https://www.nordiskagalleriet.no' });
                return;
            }

            if (payload.code === 'fi' && envConfig.NEXT_PUBLIC_FI !== 'true') {
                onSave({ link: 'https://www.nordiskagalleriet.fi' });
                return;
            }

            if (payload.code === 'dk' && envConfig.NEXT_PUBLIC_DK !== 'true') {
                onSave({ link: 'https://www.nordiskagalleriet.dk' });
                return;
            }

            const market = payload.code;
            const language = payload.languages.at(0) ?? '';

            if (state.market.code === 'no' || state.market.code === 'se') {
                onSave({
                    link: `https://www.no-ga.com/${getAppLocale(market, language)}`,
                });
                return;
            }

            onSave({
                market,
                language,
            });
        },
        [onSave, state.market.code],
    );

    return (
        <Listbox
            onChange={onChange}
            by="code"
            as="div"
            disabled={disabled}
            className={classNames?.wrapper || 'relative z-50 h-full'}>
            <Listbox.Options
                className={cn([
                    'absolute right-0 top-full w-full min-w-80 -translate-y-px divide-y border bg-white',
                    classNames?.list,
                ])}>
                {markets.filter(filter).map((market) => {
                    const language =
                        state.languages[market.languages.at(0) ?? 'en'];
                    return (
                        <MarketSelectorOption
                            key={market.code}
                            as={Listbox.Option}
                            market={market}
                            language={language}
                            value={market}
                            className="ui-selected:bg-gray-100 ui-active:bg-gray-100"
                        />
                    );
                })}
            </Listbox.Options>
            <Listbox.Button
                className={cn(
                    classNames?.input ||
                        'relative flex h-full items-center border-x border-t bg-transparent bg-white px-2 uppercase space-x-2 ui-not-open:border-transparent ui-not-open:bg-transparent',
                    {
                        'disabled:cursor-not-allowed disabled:opacity-50':
                            disabled,
                    },
                )}>
                <Flag
                    code={value.code}
                    className="h-3 w-3 rounded-full border border-black object-cover"
                />
                <span className="flex-1 text-left">{value.code}</span>
                <ArrowDownIcon />
            </Listbox.Button>
        </Listbox>
    );
}
